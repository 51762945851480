import { createApp } from "vue";

// this has to be imported before App otherwise the bootstrap
// css takes precedence of our custom one.
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import AOS from "aos";
import "aos/dist/aos.css";

import axios from "axios";
import VueAxios from "vue-axios";

import interceptor from "@/utils/interceptor.js";
interceptor();

import App from "./App.vue";
import { createHead } from "@vueuse/head";

createApp(App)
  .use(store)
  .use(router)
  .use(AOS.init)
  .use(VueAxios, axios)
  .use(createHead())
  .mount("#app");
