<template>
  <footer id="footer" class="mt-auto fixed-bottom">
    <div class="container">
      <div class="copyright">
        &copy; Copyright
        <strong
          ><span>Arnaud Blois, {{ year }}</span></strong
        >. All Rights Reserved
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped lang="scss">
#footer {
  background: #e9e8e6;
  padding: 30px 0;
  color: #222222;
  font-size: 14px;
  text-align: center;
}
#footer .credits {
  padding-top: 5px;
  font-size: 13px;
}
#footer .credits a {
  color: #34b7a7;
}
</style>
