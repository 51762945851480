/*
From https://stackoverflow.com/questions/47946680
We define an axios interceptor to use the token if it's
defined.
See also https://medium.com/swlh/
handling-access-and-refresh-tokens-using-axios-interceptors-3970b601a5da
*/

import axios from "axios";
import router from "@/router/index.js";
import store from "@/store/index.js";
import refreshToken from "@/utils/auth.js";

export default function interceptorSetup() {
  axios.interceptors.request.use(
    (config) => {
      const token = store.auth.user.token;
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      // config.headers['Content-Type'] = 'application/json';
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      const originalRequest = error.config;

      if (
        error.response.status === 401 &&
        originalRequest.url.endsWith("auth/refresh")
      ) {
        router.push("/login");
        return Promise.reject(error);
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        refreshToken();
        return axios(originalRequest);
      }
      return Promise.reject(error);
    }
  );
}
