import router from "@/router";
import App from "@/App.vue";

const getDefaultState = () => {
  return {
    user: {},
    loggedIn: false,
    accessToken: "",
  };
};

const getters = {
  isLoggedIn: (state) => {
    return state.loggedIn;
  },
  getUser: (state) => {
    return state.user;
  },
};

const mutations = {
  SET_USER: (state, user) => {
    state.user = user;
    state.loggedIn = true;
  },
  RESET: (state) => {
    state.user = {};
    state.loggedIn = false;
  },
};

const actions = {
  login: ({ commit }, { username, password }) => {
    return App.axios
      .post("auths/login/", { username, password })
      .then((response) => {
        commit("SET_USER", response.data);
      });
  },
  logout: ({ commit }) => {
    App.axios.post("auths/logout/").then(() => {
      commit("RESET", "");
      router.push("/");
    });
  },
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  actions,
  mutations,
};
