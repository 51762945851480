<template>
  <div class="home">
    <MainPageHero />
  </div>
</template>

<script>
// @ is an alias to /src
import MainPageHero from "@/components/MainPageHero.vue";

export default {
  name: "Home",
  components: {
    MainPageHero,
  },
};
</script>
