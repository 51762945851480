<template>
  <section class="not-found d-flex align-items-center">
    <div class="container d-flex flex-column align-items-center">
      <h1>404</h1>
      <h2>Page Not Found</h2>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style lang="scss">
@import "@/design";
.not-found {
  width: 100%;
  height: 70vh;
  background-size: cover;
  .container {
    padding-top: 10px;
  }
  h1 {
    font-size: 200px;
    font-weight: 700;
    line-height: 56px;
    color: $nav-item-underline-color;
  }
  h2 {
    color: #6f6f6f;
    margin: 10% 0 0 0;
    font-size: 22px;
  }
}
@media (max-width: 992px) {
  .not-found .container {
    padding-top: 58px;
  }
}

@media (min-width: 1024px) {
  .not-found {
    background-attachment: fixed;
  }
}
@media (max-width: 992px) {
  .not-found h1 {
    font-size: 5rem;
    line-height: 36px;
  }
  .not-found h2 {
    font-size: 3rem;
    line-height: 24px;
  }
}
</style>
