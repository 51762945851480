import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "./auth";

export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  modules: {
    auth,
  },
});
