<template>
  <section class="mainpagehero d-flex align-items-center">
    <div
      class="container d-flex flex-column align-items-center"
      data-aos="zoom-in"
      data-aos-delay="100"
    >
      <h1>Arnaud Blois</h1>
      <h2>I'm a web dev and Python expert from London</h2>
      <router-link to="/about" class="active btn-about" aria-current="page">
        About Me
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "MainPageHero",
};
</script>

<style lang="scss">
@import "@/design";
.mainpagehero {
  width: 100%;
  height: 100vh;
  background: url("../assets/img/hero-bg-flip-2.jpeg") top right;
  //background-image: url("../assets/img/hero-bg-flip-2.jpeg");
  background-size: cover;
  padding-top: 0px;
  .container {
    padding-top: 70px;
    position: relative;
  }
  h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: $header-title-color;
  }
  h2 {
    color: #6f6f6f;
    margin: 10px 0 0 0;
    font-size: 22px;
  }
  .btn-about {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 50px;
    transition: 0.5s;
    margin-top: 30px;
    color: #fff;
    background: $resume-sideline-color;
  }
  .btn-about:hover {
    background: #3dc8b7;
  }
}
@media (max-width: 992px) {
  .mainpagehero .container {
    padding-top: 58px;
  }
}

@media (min-width: 1024px) {
  .mainpagehero {
    background-attachment: fixed;
  }
}
@media (max-width: 992px) {
  .mainpagehero {
    background-image: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0.8)
      ),
      url("../assets/img/hero-bg-flip-2.jpeg");
    background-position: 78% 100%;
  }
  .mainpagehero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  .mainpagehero h2 {
    font-size: 18px;
    line-height: 24px;
  }
}
</style>
