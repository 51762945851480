<template>
  <header>
    <nav
      class="navbar navbar-expand-md navbar-light main-nav"
      aria-label="Main navigation"
    >
      <a class="navbar-brand" href="/" aria-label="Arnaud"> Arnaud </a>

      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#bdNavbar"
        aria-controls="bdNavbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="bdNavbar">
        <hr class="d-md-none text-red-50" />
        <ul
          class="navbar-nav flex-row flex-wrap col-md-10 justify-content-center"
        >
          <li class="nav-item col-10 col-md-auto">
            <router-link to="/" class="active nav-link" aria-current="page">
              Home
            </router-link>
          </li>
          <li class="nav-item col-10 col-md-auto">
            <router-link to="/about" class="nav-link"> About </router-link>
          </li>
          <li class="nav-item col-10 col-md-auto">
            <router-link to="/resume" class="nav-link"> Resume </router-link>
          </li>
          <li class="nav-item col-10 col-md-auto">
            <router-link to="/portfolio" class="nav-link">
              Projects
            </router-link>
          </li>
          <li class="nav-item col-10 col-md-auto">
            <router-link to="/blog" class="nav-link"> Blog Posts </router-link>
          </li>
          <li class="nav-item col-10 col-md-auto">
            <router-link to="/contact" class="nav-link bd-nav-link">
              Contact
            </router-link>
          </li>
        </ul>

        <hr class="d-md-none" />

        <ul class="navbar-nav flex-row flex-wrap justify-content-center">
          <li class="nav-item col-4 col-md-auto header-icon">
            <a
              href="https://github.com/arnaudblois"
              class="github nav-link"
              target="_blank"
            >
              <i class="bi bi-github"></i>
            </a>
          </li>
          <li class="nav-item col-4 col-md-auto header-icon">
            <a
              href="https://www.linkedin.com/in/arnaudblois/"
              class="linkedin nav-link"
              target="_blank"
            >
              <i class="bi bi-linkedin"></i>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style lang="scss">
@import "@/design";

header {
  background: $header-bg-color;
  color: $navbar-font-color;
  text-align: left;

  .header-icon {
    margin-left: 10px;
    margin-right: 10px;
  }
  .main-nav {
    padding-top: $header-padding;
    padding-bottom: $header-padding;
    padding-right: 1.5rem;
  }

  hr {
    border: 0.2rem solid $nav-item-underline-color;
    border-radius: 30%;
  }

  .nav-link {
    color: $navbar-font-color;
    text-decoration: none;
    font-family: "Raleway", sans-serif;
    font-size: 0.9rem;
    margin-right: 1rem;
    font-weight: 600;
  }

  nav li:hover,
  nav li.router-link-active,
  nav li.router-link-exact-active {
    cursor: pointer;
    text-decoration: none;
  }

  .nav-item {
    display: inline-block;
    position: relative;

    white-space: nowrap;
    transition: 0.3s;
    position: relative;
  }

  .router-link-active:after,
  .router-link-exact-active:after {
    content: "";
    position: absolute;
    width: 25%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $nav-item-underline-color;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .nav-item:after {
    content: "";
    position: absolute;
    width: 25%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $nav-item-underline-color;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .nav-item:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .navbar-brand {
    font-family: "Raleway", sans-serif;
    font-size: 32px;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    margin-right: 100px;
    margin-left: 30px;
    color: $header-title-color;
    a {
      text-decoration: none;
    }
    img {
      max-height: 40px;
    }
  }
}
</style>
