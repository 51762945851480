<template>
  <Header />
  <section class="main-content">
    <router-view />
    <div class="position-fixed bottom-0 end-0 m-3" style="z-index: 99999">
      <div
        id="liveToast"
        class="toast update-toast"
        v-bind:class="{ show: updateExists }"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="toast-header">
          <strong class="me-auto">New version released 🎉</strong>
        </div>
        <div class="toast-body">
          <p>Some new content is available; please refresh</p>
          <button type="button" aria-label="Refresh" @click="refreshApp">
            Refresh
          </button>
        </div>
      </div>
    </div>
  </section>

  <Footer />
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

import update from "./mixins/update";
//import { Head } from "@vueuse/head";
import { useHead } from "@vueuse/head";
//import { inject } from "vue";

const longTitle = "Arnaud Blois' personal page";
const description =
  "Arnaud Blois' personal website - Python expert and web developer";
const opengraphUrl =
  process.env.VUE_APP_BASE_URL +
  require("./assets/img/opengraph-thumbnail.jpeg");

export default {
  name: "App",
  components: {
    Footer,
    Header,
    //Head,
  },
  mixins: [update],
  setup() {
    useHead({
      title: "Arnaud Blois",
      meta: [
        {
          name: "description",
          content: description,
        },
        // Addding social sharing meta - see doc here
        // https://vueschool.io/articles/vuejs-tutorials/vueuse-head-and-netlify-prerendering-for-seo-and-social-friendly-spas/
        // Social -->
        { property: "og:title", content: longTitle },
        {
          property: "og:description",
          content: description,
        },
        { property: "og:type", content: "website" },
        {
          property: "og:image",
          itemprop: "image",
          content: opengraphUrl,
        },
        {
          property: "og:image:secure_url",
          content: opengraphUrl,
        },
        { property: "og:image:type", content: "image/jpeg" },
        { property: "og:image:alt", content: "Arnaud Blois" },
        { property: "og:image:width", content: "1200" },
        { property: "og:image:height", content: "675" },
        // Twitter -->
        { name: "twitter:title", content: longTitle },
        { name: "twitter:description", content: description },
        {
          name: "twitter:image",
          content: opengraphUrl,
        },
        { name: "twitter:card", content: "summary_large_image" },
      ],
    });
  },
};
</script>

<style lang="scss">
@import "@/design";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.update-toast {
  border: 1px solid #3eaf7c;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.section-bg {
  background-color: #e7f8f6;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
  h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
  }
  h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #34b7a7;
    bottom: 0;
    left: calc(50% - 25px);
  }
  h3 {
    font-size: 26px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 15px;
    padding-bottom: 15px;
    position: relative;
  }
  h3::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #34b7a7;
    bottom: 0;
    left: calc(40% - 25px);
  }
  p {
    margin-bottom: 0;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #34b7a7;
  text-decoration: none;
}

a:hover {
  color: #51cdbe;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
}

#main {
  margin-top: 50px;
}
@media (max-width: 992px) {
  #main {
    margin-top: 30px;
  }
}
</style>
